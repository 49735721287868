.boton-gen{
    background-color: #0B80CB;
    border-radius: 20px;
    border: none;
    opacity: 0.8;
    height: 35px;
    color: white;
    padding: 1px 20px 1px 20px;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.boton-gen:focus{
    outline: none;
}

.boton-gen:hover{
    border:none;
    outline: none;
    opacity: 1;
}

.boton-gen:active{
    border:none;
    outline: none;
    opacity: 1;
}

.botongen-grande{
    height: 40px;
    font-size: medium;
    background-color: #1087D6;
}