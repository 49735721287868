.button-ver{
    background-color: #4f779e;
    color:white;
    border-radius: 10px;
    padding: 10px;
    margin-left: -50px;
    border: none;
}

.button-ver:hover{
    background-color: #005ac2;
}

.seleccion{
    margin-left: 30px;
    font-size: large;
}

.picker-cont{
    margin: 0 4% 0 4%;
}