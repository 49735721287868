.logourdigital{
    display: block;
    margin: 2% 3%;
    width: 60%;
    max-width: 60vw;
}

.rootl{
    padding: 0% 5% 5% 5%;
}

.tituloTuro{
    text-align: center;
    color: #24456A;
    font-size: 70px;
}

.logoturo{
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 38%;
    margin-top: 3%;
    max-width: 40vw;
}

.sub-dos{
    text-align: center;
    /* margin-right: auto;
    margin-left:auto; */
    color: #575757;
    margin-bottom: 30px;
    font-size: large;
}

/* .contentLogo{
    margin: auto;
    width: 50%;
    border: 3px solid green;
    padding: 10px;   
} */