.rootCell{
    padding: 10px;
}

.cell{
    height: 70px;
    width: 100%;
    background-color: rgb(235, 235, 255);
    position: relative;
    border-radius: 15px 15px 0px 0px;
    box-shadow: 3px 3px 10px -1px rgba(0,0,0,0.25);
}

.cell-text{
    text-align: center;
    padding-top: 12px;
    background-color: transparent;
}

.cellbar{
    height: 20px;
    bottom: 0px;
    position: absolute;
    display: inline-flex;
}

.cellbar-v1{
    /* width: 20%; */
    background-color: rgb(47, 204, 243);
}

.cellbar-v2{
    width: 40%;
    left: 20%;
    background-color: rgb(43, 226, 58);
}

.cellbar-v3{
    width: 40%;
    left: 60%;
    background-color: rgb(233, 61, 113);
}

.tooltip {
    display: inline-block;
  }
  
  .tooltip .tooltiptext {
    visibility: hidden;
    width: 140px;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    font-size: 12px;
    padding: 5px 0;
    margin: -40px;
    bottom: 0;
    position: absolute;
    z-index: 8;
  }
  
  .tooltip:hover .tooltiptext {
    visibility: visible;
  }

  .cell:hover{
      width: 110%;
      height: 73px;
      margin-left: -5px;
  }