.title-container{
    padding: 30px;
}

.tittle{
    color: #24456A;
    font-size: 48px;
}

.subtitle{
    padding: 1px;
    margin: 1px;
    margin-left: 100px;
    color: #49607a;    
}

.line{
    padding: 0px;
    margin-bottom: 15px;
    width: 300px;
    height: 2px;
    background-color: rgb(0, 80, 133);
}