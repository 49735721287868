.text-wll{
    color:white;
}

.tit-wll{
    color: white;
    font-size: 23px;
    padding-top: 40px;
}

.boton-st{
    height: 30px;
    border-radius: 10px;
    width: 150px;
    border: none;
    background-color: #E9F2FE;
    color: #005ac2;
    border-radius: 30px;
}

.boton-st:hover{
    background-color: #005ac2;
    color: white;
}

.groups{
    padding: 15px;
    height: 60px;
    border-radius: 12px;
    background-color: #6B88A8;
}

.groups:hover{
    background-color: rgba(35, 137, 255, 0.7);
}

.expand-group{
    padding-left: 50%;
    background-color: transparent;
    border: none;
}

.group-text{
    color: white;
    padding: 2px;
}

