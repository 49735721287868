.link-student{
    text-decoration: none;   
}

.table-head{
    color:white;
}

.MuiTableHead-root{
    background-color:#005ac2;
}

/* .MuiTableRow-root{
    background-color: aliceblue;
} */

.button-detalles{
    background-color: #1976d2;
    color:white;
    border-radius: 15px;
    border:none;
    padding: 12px;
}

.button-detalles:hover{
    background-color: #1e88f3;
}

.csvdow{
    padding: 20px;
}