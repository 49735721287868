.superior {
    position: sticky;
    top: 0;
    height: 80px;
    width: 100vw;
    background-color: #1976d2;
    padding-bottom: 10px;
    font-size: 20px;
  }

  .logo-menu{
    max-height: 100%;
    max-width: 100%;
    object-fit:contain;
  }

  .texto-menu{
    font-size: 30px;
    color: white
  }

  .menu-con{
    position: relative;
    display: none;
  }

  .menu-res{
      position: absolute;
      top: 0vh;
      width: 14vw;
      min-width: 250px;
      background-color: rgba(0, 0, 0, 0.8);
      height: 90vh;
      z-index: 1;
  }

  .menu-contenido{
    padding-top: 50px;
    color: white;
    padding-left: 15px;
    font-size: 18px;
  }

  .menu-element{
    margin: 0;
    display: block;
    /* width: 100%; */
    border:none;
    color: white;
    font-size: 18px;
    background-color: transparent;
    padding: 10px;
    text-align: left;
    text-decoration: none;
  }

  .text-menu-element{
    margin-left: 10px;
  }

  .menu-element:hover{
    background-color: #1976d2;
  }

  .menu-element:active{
    background-color: #1560ac;
  }

  .menu-button{
    background-color: transparent;
    border: none;
    color: white;
    padding-top: 16px;
    width: 60px;
    height: 60px;
    font-size: 25px;
  }
