.tabla{
    margin: 10px;
}

.titulo{
    color: #24456A;
    margin: 25px;
}

.subtitulo{
    color: #4c4747;
    margin: 7px 25px 25px 25px;
}

.link-repote{
    color: blueviolet;
}