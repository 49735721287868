.formulario{
    padding: 0px 0px 0px 0px;
}

.logos{
    width: 30vw;
    padding-top: 0px;
}

.inputs{
    margin-left: -150px;
}

.botonLog{
    padding: 150px;
}

.contenido{
    margin-top: 5%;
    height: "100vh"
}

.botonLog{
    padding: "20px"
}