#wellcome{
    min-width: 100vw;
    background-color: #E9F2FE;
    padding: 0px;
    min-height: 97vh;
    background-attachment: fixed;
    padding-bottom: 3vh;
}

.root{
    padding: 0;
    background-color: #E9F2FE;
    background-size: cover;
}

.text-wll{
    color:rgb(126, 126, 126);
}

.tit-wll{
    color: #1B78D2;
    font-weight: bold;
    font-size: 40px;
    padding-top: 20px;
}

.expand-right-element{
    display: none;
}

.text-semestre{
    color: #1B78D2;
    font-size: 20px;
}


.boton-st:hover{
    background-color: #005ac2;
}


.expand-group{
    padding-left: 50%;
    background-color: transparent;
    border: none;
}


.semBut{
    height: 40px;
    font-size: large;
    background-color: transparent;
    border: none;
    color: #1B78D2;
}

.semBut:hover{
    color: #458f85;
}

.sem{
    margin-left: 10px;
}