.input{
    color: white;
    height: 32px;
    background-color: #0B80CB;
    border: none;
    opacity: 0.4;
    border-radius: 15px;
    text-align: center;
    margin: 10px;
    width: 180%;
}

.input::placeholder{
    color: white;
    opacity: 0.5;
}

.input:active{
    border:none;
    opacity: 0.4;
}

.input:focus{
    outline:none;
    opacity: 0.6;
}