.user-icon{
    width: 120px;
    height: 120px;
    background-size: cover;
    opacity: 0.7;
    background-image: url("../../../../assets/images/user.png");
}

.name{
    color: #004ba0;
}

.email{
    color: #1976d2;
}

.subtitles{
    color: #777777;
}

.modal-students{
    background-color: rgb(255, 255, 255);
    width: 50vw;
    padding: 5vw;
    margin: 15vh 20vw 15vh 20vw;
    min-height: 40vh;
    border-radius: 30px;
    box-shadow: 5px 5px 15px 5px #dbdbdb;
    max-height: 60vh;
    overflow-y: auto;
}

.btn-est-modal{
    background-color: #1976d2;
    border:none;
    border-radius: 15px;
    padding: 8px 12px 8px 12px;
    color:white;
}

.btn-est-modal:hover{
    background-color: #2d90f3;
}