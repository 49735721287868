.recamara{
    text-align: center;
    /* background-color: seagreen; */
    /* border: solid rgb(218, 218, 218); */
}

.pisos{
    overflow-y: scroll;
    height: 60vh;
}

.select{
  width: 20vw;
  padding: 0 0 30px 50px;
}

/* width */
::-webkit-scrollbar {
    width: 10px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 4px rgba(196, 196, 196, 0.5); 
    border-radius: 10px;
  }
   
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: rgba(156, 156, 156, 0.5); 
    border-radius: 5px;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #797979; 
  }